import request from "@/api/service";

const api = {
   //获取 报警规则列表
   getAlarmRules:'/quartz/iot_alarmRules/getPage',
   // 新增 报警规则
   addAlarmRules:"/quartz/iot_alarmRules/add",
   // 编辑 报警规则
   editAlarmRules:"/quartz/iot_alarmRules/update",
   // 删除 报警规则
   delAlarmRules:'/quartz/iot_alarmRules/delete',
   // 获取常、非常待电设备
   getGroupByProductId:'/quartz/iot_alarmRules/getGroupByProductId',

   //获取 报警信息 分页
   getAlarmPage:"/device-hotel/iot_deviceAlarm/getPage",
}
//获取 酒店列表
export function getAlarmRules(data){
   return request({
      url:api.getAlarmRules,
      method:'get',
      params:{
         ...data
      }
   })
}
//添加/编辑酒店
export function addEditAlarmRules(data,state){
   return request({
      url:state === 'add' ? api.addAlarmRules : api.editAlarmRules,
      method:'post',
      headers:{responseType: 'JSON'},
      data:data,
   })
}
//删除酒店
export function delAlarmRules(data){
   return request({
      url:api.delAlarmRules+ `/${data.id}`,
      method:'post',
      headers:{"responseType": 'JSON'},
      data:data,
   })
}

//
export function getGroupByProductIds(id){
   return request({
      url:api.getGroupByProductId + `/${id}`,
      method:'get',
   })
}
// 获取 报警信息 分页
export function getAlarmPage(data){
   return request({
      url:api.getAlarmPage,
      method:'get',
      params:{
         ...data
      }
   })
}



